import React,{useState,useCallback,useEffect } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Invoices from '../components/Invoices';
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
  GoogleReCaptcha,
 
} from 'react-google-recaptcha-v3';

export default function Home() {
 
  const apiEndPoint = process.env.REACT_APP_API_ENDPOINT;
  const [data, setData] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [accountNumber, setAccountNumber] = useState('');
  const [invoiceNumber, setInvoiceNumber] = useState('');
  const { executeRecaptcha } = useGoogleReCaptcha();
  
    // get invoice data
    const fetchData = async (token) => {
      // validate
      if(accountNumber.length < 1 || invoiceNumber.length < 1) {
        toast.error('Please fill Account number and Invoice number to proceed.', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      } else {
        setProcessing(true);
        const response = await fetch(apiEndPoint + "/getDetails", {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            "TransactionNumber":invoiceNumber,
            "CustomerNumber":accountNumber,
            "g-recaptcha-response" : token
          })
        });
        const responseData = await response.json();
        if(responseData.status == 'success') {
          setData(responseData.data);
        } else {
          toast.error(responseData.data, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
          setProcessing(false);
        }
        
      }  
  };

 
  const handleVerify = useCallback(async () => {
    try {
      const token = await executeRecaptcha();
      if (token) {
        console.log('Recaptcha token:', token);
        await fetchData(token);
      } else {
        throw new Error('Invalid reCAPTCHA token');
      }
    } catch (error) {
      console.error(error);
      toast.error('Failed to verify reCAPTCHA. Please try again later.', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
      });
    }
  }, [executeRecaptcha, fetchData]);

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleVerify()
    }
  }

 
  return (
    <div className="flex flex-row p-10 items-center justify-center w-[70%] h-[auto] rounded-[15px] bg-black/90">
      <ToastContainer />  
      { !data ? (   
      <div className="flex flex-row hidden md:lg:block"><img src='/logo_color.png' className='w-[250px] mr-12'></img></div>
      ) : null} 
      
      
      { !data ? (   
      <div className="flex flex-col md:w-[60%] w-[100%]">
        <div>
          <label class="block md:text-lg text-[15px] text-white mb-2">Customer Account Number</label>
          <input type="text" class="py-3 px-5 block w-full border-gray-200 rounded-full text-[20px] font-bold focus:border-blue-500
          focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400" placeholder="Account Number"
          value={accountNumber} onChange={e => setAccountNumber(e.target.value)} onKeyPress={handleKeyPress} />
        </div>
        <div className='mt-5'>
          <label class="block md:text-lg text-[15px] text-white mb-2">Invoice Number</label>
          <input type="text" class="py-3 px-5 block w-full border-gray-200 rounded-full text-[20px] font-bold focus:border-blue-500
          focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400" placeholder="Invoice Number"
          value={invoiceNumber} onChange={e => setInvoiceNumber(e.target.value)} onKeyPress={handleKeyPress}   />
        </div>
       
        
        <div className='mt-8 items-center'>
          {!processing ? (
            <>
 
            <button type="button" class="py-3 px-4 lg:w-[100%] w-[100%] inline-flex justify-center items-center gap-2 rounded-md border border-transparent 
              bg-indigo-900 text-white hover:bg-red-600
              transition-all text-[20px] dark:focus:ring-offset-gray-800 border-transparent focus:outline-none"
              onClick={ ()=> handleVerify()}>
              Proceed
            </button>
            </>
           ) :  (
            <div class="animate-spin items-center justify-center w-9 h-9 border-[3px] border-current border-t-transparent text-blue-600 rounded-full" role="status" aria-label="loading">
              <span class="sr-only">Processing...</span>
            </div>
           ) }
        </div>
       



        
      </div>
       ) : null }
      
      { data ? (
        <div className="flex flex-row w-[100%]">
          <Invoices data={data} />
        </div>
        ) : null }
       
    </div>
  )
}
