import React from 'react'
import 'react-toastify/dist/ReactToastify.css';

export default function Cancelled() {
  return (
    <div className="flex items-center justify-center md:p-10 md:w-[80%] lg:w-[80%] w-[100%] p-0  h-[auto] rounded-[15px] bg-black/60">
      <div class="flex flex-col w-[100%] bg-white border shadow-sm rounded-xl dark:bg-gray-800 dark:border-gray-700 dark:shadow-slate-700/[.7]">
        <div class="bg-gray-100 border-b rounded-t-xl py-3 px-4 md:py-4 md:px-5 dark:bg-gray-800 dark:border-gray-700">
            <p class="mt-3 text-lg font-bold text-gray-500 dark:text-gray-500">
            <div className="flex flex-row"><img src='/img/delete.png' className='w-10 mr-5 object-cover object-center'></img><h1 className='text-black text-[25px]'>Payment Cancelled</h1></div></p>
        </div>
        <div class="p-4 md:p-5">              
                <h2 className='font-bold p-2 text-lg'>Your payment was cancelled. <br/> <a type="button" class="py-3 mt-8 px-4 inline-flex justify-center items-center gap-2 rounded-md border-2
                            border-green-500 font-semibold text-white  
                        focus:outline-none focus:ring-2
                            focus:ring-offset-2 transition-all text-lg  
                                bg-green-500"
                                href={"/"}>
                        Pay Again
                        </a></h2>
                </div>
        </div>
        
    </div>
  )
}
