import logo from './logo.svg';
import './App.css';
import Home from './screens/Home';
import Complete from './screens/Complete'; 
import Cancelled from './screens/Cancelled'; 
import NotFound from './screens/NotFound';
import Failed from './screens/Failed';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
  GoogleReCaptcha,
 
} from 'react-google-recaptcha-v3';

function App() {
  return (
    <GoogleReCaptchaProvider reCaptchaKey="6LdWDtUlAAAAAIPxs93aeFHQld018noWQuxoNQKq">
    <div className="container md:container md:mx-auto flex h-screen items-center justify-center">
      <BrowserRouter>
      <Routes>
          <Route exact path="/" element={<Home/>} />
          <Route path="/complete/:trxID/:imxID/:imtrID" element={<Complete/>} />
          <Route path="/cancelled/:imxID/:imtrID" element={<Cancelled/>} />
          <Route path="/failed/:imxID/:imtrID" element={<Failed/>} />
          <Route path="*" element={<NotFound/>} />
        </Routes>
        
      </BrowserRouter>
    </div>
    </GoogleReCaptchaProvider>
  );
}

export default App;
