import React, { useEffect, useState, useRef } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useParams } from 'react-router-dom';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import moment from 'moment';


export default function Complete(props) { 

  const apiEndPoint = process.env.REACT_APP_API_ENDPOINT;
  const [data, setData] = useState('');
  const [dataReady, setDataReady] = useState(false);
  const { trxID,imxID,imtrID } = useParams();
  const [canvasSizeWidth, setCanvasSizeWidth] = useState(0);
  const [canvasSizeHeight, setCanvasSizeHeight] = useState(0);
  const myRef = useRef();
  const docRef = useRef();
  
 

  // get invoice data
  const fetchData = async () => {
    // validate
    if(trxID?.length < 1) {
      toast.error('Please fill Account number and Invoice number to proceed.', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } else {
      const response = await fetch(apiEndPoint + "/payment_complete", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          "signature":trxID,
          "imxID":imxID,
          "imtrID" : imtrID
        })
      });
      const responseData = await response.json();
      if(responseData.status === "success") {
        setDataReady(true);
        setData(responseData.data)
      } else {
        toast.error('Unable to complete this transaction.', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
      }
   
    }  
    };
 

    // generate pdf
    const handleGeneratePDF = async (number) => {
        const div =  myRef.current;
        const canvas = await html2canvas(div, { scale: 2 });
        const imgData = canvas.toDataURL('image/png');
        const options = {
            orientation: 'portrait',
            unit: 'pt',
            format: 'a4',
            dpi: 800, // set the DPI to 300,
            quality:1000,
            
          };

        const pdf = new jsPDF(options);
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        pdf.addImage(imgData, 'PNG', 10, 10, pdfWidth - 20, pdfHeight - 20, '', 'SLOW');
        pdf.save('receipt-'+number+'.pdf');

    };

    // print 

    const printDiv = () => {
        const divRef = myRef.current.innerHTML;    
        const printContents = divRef;
        const originalContents = document.body.innerHTML;
    
        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;
    };
  

    useEffect(()=>{
        if(trxID) {
            fetchData();
        }       
        const canvas = docRef.current;
        const width = document.documentElement.offsetWidth/2;
        const height = document.documentElement.offsetHeight/2;
        setCanvasSizeWidth(width);
        setCanvasSizeHeight(height);
    },[])
  
    const formatCurrency = (amount) => {
      const formattedAmount = new Intl.NumberFormat('en-US', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(amount);
      return formattedAmount;
    };

  return (
    <div className="flex items-center justify-center md:p-10 md:w-[80%] lg:w-[80%] w-[100%] p-0  h-[auto] rounded-[15px] bg-black/60" ref={docRef}>    
    <ToastContainer /> 
      {dataReady ? (
      <div class="flex flex-col w-[100%] bg-white border shadow-sm rounded-xl dark:bg-gray-800 dark:border-gray-700 dark:shadow-slate-700/[.7]">
        <div class="bg-gray-100 border-b rounded-t-xl py-3 px-4 md:py-4 md:px-5 dark:bg-gray-800 dark:border-gray-700">
            <p class="mt-3 text-lg font-bold text-gray-500 dark:text-gray-500">
            <div className="flex flex-row"><img src='/img/tick.png' className='w-10 mr-5 object-cover object-center'></img><h1 className='text-black text-[25px]'>Payment Successful</h1></div>

            </p>
        </div>
        <div class="p-4 md:p-5">
            <div ref={myRef}>
            <div className="flex flex-row">
                <div className="float-right w-[100%]">
                    <img src='/log_bw.png' className='w-[250px] float-right'/>
                </div>
            </div>
            <div className="flex flex-col text-center mt-8">
                <h3 class="text-[30px] font-bold text-gray-800 dark:text-white">
                    Payment Receipt
                </h3>
            </div>
            <p class="mt-2 text-gray-800 dark:text-gray-400 mt-6">
                <div><span  className="font-bold text-[18px]">Invoice Number : </span> <span className="text-[18px]">{data.invoiceNumber}</span></div> 
                <div><span  className="font-bold text-[18px]">Customer : </span> <span className="text-[18px]">{data.customerName}</span></div> 
                <div><span  className="font-bold text-[18px]">Invoice Type : </span> <span className="text-[18px]">{data.invoiceCategory}</span></div> 
                <div><span  className="font-bold text-[18px]">Transaction ID : </span> <span className="text-[18px]">{data.paymentGatewayTrxNumber.toUpperCase()}</span></div> 
                <div><span  className="font-bold text-[18px]">Transaction Date : </span> <span className="text-[18px]">{moment(data.trxDate).format('Do MMMM YYYY')}</span></div> 
  
            </p>
            <div class="border rounded-lg overflow-hidden mt-6">
                    <table class="min-w-full divide-y">
                    <thead class="bg-gray-100">
                        <tr>
                        <th scope="col" class="px-6 py-3 text-left text-[16px] font-medium text-gray-900 uppercase dark:text-gray-400">#</th>
                        <th scope="col" class="px-6 py-3 text-center text-[16px] font-medium text-gray-900 uppercase dark:text-gray-400">Particulars</th>
                        <th scope="col" class="px-6 py-3 text-center text-[16px] font-medium text-gray-900 uppercase dark:text-gray-400">Amount ({data.invoiceCurrencyCode})</th>
                        </tr>
                    </thead>
                    <tbody class="divide-y divide-gray-200 bg-white dark:divide-gray-700">                     
                            <tr>
                            <td className="px-6 py-4 whitespace-nowrap text-[16px] font-medium text-gray-800 dark:text-gray-200">1</td>
                            <td className="px-6 py-4 whitespace-nowrap text-center text-[16px] text-gray-800 dark:text-gray-200">Online Payment</td>
                            <td className="px-6 py-4 whitespace-nowrap text-center text-[16px] text-gray-800 dark:text-gray-200">{formatCurrency(data.invoiceAmount)}</td>                          
                            </tr>            
                    </tbody>
                    <tfoot class="bg-gray-100">                   
                            <tr>
                            <td colSpan={2} className="px-6 py-4 whitespace-nowrap text-[16px] font-bold text-gray-800 dark:text-gray-200">Total</td>
                            <td className="px-6 py-4 whitespace-nowrap text-center text-[16px] font-bold text-gray-800 dark:text-gray-200">{formatCurrency(data.invoiceAmount)}</td>                          
                            </tr>            
                    </tfoot>
                    </table>
                </div>
                <h2 className='font-bold p-2'>Note: This is a system generated receipt of the payment.</h2>
                </div>
                <div className="flex flex-row justify-center items-center">                
                        <a type="button" class="py-3 mt-8 px-4 inline-flex justify-center items-center gap-2 rounded-md border-2
                            border-green-500 font-semibold text-white  
                        focus:outline-none focus:ring-2
                            focus:ring-offset-2 transition-all text-lg  
                                bg-green-500"
                                href={"/"}>
                        Pay Again
                        </a> 
                        <button type="button" class="py-3 ml-3 mt-8 px-4 inline-flex justify-center items-center gap-2 rounded-md border-2
                            border-blue-800 font-semibold text-white  
                        focus:outline-none focus:ring-2
                            focus:ring-offset-2 transition-all text-lg  
                                bg-blue-800"
                                onClick={printDiv}>
                        Print
                        </button>    
                        <button type="button" class="py-3 ml-3 mt-8 px-4 inline-flex justify-center items-center gap-2 rounded-md border-2
                            border-blue-950 font-semibold text-white  
                        focus:outline-none focus:ring-2
                            focus:ring-offset-2 transition-all text-lg  
                                bg-blue-950"
                                onClick={()=>handleGeneratePDF(data.invoiceNumber)}>
                        Save (PDF)
                        </button>
                   
                </div>     
        </div>
        </div>
        ): <div class="animate-spin items-center inline-block w-10 h-10 border-[3px] border-current border-t-transparent text-white rounded-full" role="status" aria-label="loading">
        <span class="sr-only">Loading...</span>
        </div>
        }
      
    </div>
  )
}
