import React,{useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export default function Invoices(props) {   
    const [processing, setProcessing] = useState(false);
    const apiEndPoint = process.env.REACT_APP_API_ENDPOINT;
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const [customerID, setCustomerID] = useState('');
    const [customerName, setCustomerName] = useState(false);
    const [customerNumber, setCustomerNumber] = useState('');
    const [customerTrxID, setCustomerTrxID] = useState('');
    const [trxNumber, setTrxNumber] = useState('');
    const [trxDate, setTrxDate] = useState('');
    const [invoiceCurrencyCode, setInvoiceCurrencyCode] = useState('');
    const [invoiceNumber, setInvoiceNumber] = useState('');
    const [invoiceCategory, setInvoiceCategory] = useState('');
    const [invoiceAmount, setInvoiceAmount] = useState('');
    const [remmitanceBankAccountNumber, setRemmitanceBankAccountNumber] = useState('');
    const [businessUnit, setBusinessUnit] = useState('');

    const openModal = () => {
        setIsOpen(true);
    };    
    const closeModal = () => {
        setIsOpen(false);
    };

   // pay to gateway                               
   const payNow = (CUSTOMER_ID,CUSTOMER_NAME,CUSTOMER_NUMBER,CUSTOMER_TRX_ID,TRX_NUMBER,TRX_DATE,INVOICE_CURRENCY_CODE,INVOICE_NO,INVOICE_CATEGORY,AMOUNT_DUE_REMAINING,REMMITANCE_BANK_ACCOUNT_NUMBER, BU) => {
     // validate the data first
     if(AMOUNT_DUE_REMAINING.length < 1 ||      
        CUSTOMER_TRX_ID.length < 1 || 
        TRX_DATE.length < 1 || 
        INVOICE_NO.length < 1 ||
        INVOICE_CURRENCY_CODE.length < 1 || 
        CUSTOMER_NAME.length < 1) {
            toast.error('Unable to process your request, try again later!.', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
     } else {    
        // set vars to dsiplay on prommpt
        setCustomerID(CUSTOMER_ID);
        setCustomerName(CUSTOMER_NAME);
        setCustomerNumber(CUSTOMER_NUMBER);
        setCustomerTrxID(CUSTOMER_TRX_ID);
        setTrxNumber(TRX_NUMBER);
        setTrxDate(TRX_DATE);
        setInvoiceCurrencyCode(INVOICE_CURRENCY_CODE);
        setInvoiceNumber(INVOICE_NO);
        setInvoiceCategory(INVOICE_CATEGORY);
        setInvoiceAmount(AMOUNT_DUE_REMAINING);
        setRemmitanceBankAccountNumber(REMMITANCE_BANK_ACCOUNT_NUMBER);
        setBusinessUnit(BU)
        setIsOpen(true)
     }

   }

   const initPaymentGateway = async () => {
     // disable button
    setProcessing(true);
    const response = await fetch(apiEndPoint + "/pay", {
        method: 'POST',
        headers: {
        'Content-Type': 'application/json'
        },
        body: JSON.stringify({
        "invoiceAmount":invoiceAmount,
        "invoiceCurrencyCode":invoiceCurrencyCode,
        "customerName" : customerName,
        "invoiceNumber" : invoiceNumber,
        "customerAccountNumber": customerNumber,
        "invoiceCategory" : invoiceCategory,
        "trxNumber" : trxNumber,
        "trxDate" : trxDate,
        "customerTrxID" : customerTrxID,
        "remmitanceBankAccountNumber": remmitanceBankAccountNumber,
        "businessUnit": businessUnit
        })
    });
    const responseData = await response.json();
    
    if(responseData['status'] == "success") {
        window.location.href = responseData['message'];
    } else {
        toast.error(responseData['message'], {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
        });
    }
 
    
    
   }

  return (
    <div className='w-[100%]'>
      
        {isOpen && (
            <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div>

                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

                <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                        <svg className="h-6 w-6 text-green-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                        </svg>
                    </div>

                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <h3 className="leading-6 text-[25px] font-bold text-gray-900">Confirm Payment</h3>
                        <div className="mt-2">
                        <p className="text-lg text-gray-500">By proceeding, you are agreeing our <a href="https://www.macl.aero/privacy/payments" target='_blank' className='font-bold'><u>Privacy Policy</u></a> and to pay {invoiceCurrencyCode} {invoiceAmount}. Would you like to continue?</p>
                        </div>
                    </div>
                    </div>
                </div>

                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                    <button type="button" 
                    className="w-full inline-flex justify-center rounded-md border 
                    border-transparent px-4 py-2 bg-red-600 text-[16px] text-white
                     hover:bg-red-700 focus:outline-none focus:ring-2 
                     focus:ring-offset-2 focus:red-500 sm:ml-3 sm:w-auto"                      
                     onClick={closeModal}>
                        Cancel
                    </button>
                    {!processing ? (
                    <button type="button" className="w-full inline-flex justify-center rounded-md border 
                    border-transparent px-4 py-2 bg-green-600 text-[16px] font-medium text-white
                     hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 
                     sm:ml-3 sm:w-auto" onClick={initPaymentGateway}>
                        Yes, Proceed
                    </button>
                    ) :  (
                        <div class="animate-spin items-center justify-center w-9 h-9 border-[3px] border-current border-t-transparent text-blue-600 rounded-full" role="status" aria-label="loading">
                          <span class="sr-only">Processing...</span>
                        </div>
                    ) }
                    
                </div>
                </div>
            </div>
            </div>
        )}



        <div class="flex flex-col mt-0">
            <div class="-m-1.5 overflow-x-auto rounded-[15px]">
                <div class="p-1.5 min-w-full inline-block align-middle">
                <div class="border rounded-lg overflow-hidden ">
                    <table class="min-w-full divide-y">
                    <thead class="bg-gray-300">
                        <tr>
                        <th scope="col" class="px-6 py-3 text-left text-[16px] font-medium text-gray-900 uppercase dark:text-gray-400">CUSTOMER</th>
                        <th scope="col" class="px-6 py-3 text-center text-[16px] font-medium text-gray-900 uppercase dark:text-gray-400">INVOICE NUMBER</th>
                        <th scope="col" class="px-6 py-3 text-center text-[16px] font-medium text-gray-900 uppercase dark:text-gray-400">TYPE</th>
                        <th scope="col" class="px-6 py-3 text-center text-[16px] font-medium text-gray-900 uppercase dark:text-gray-400">AMOUNT</th>
                        <th scope="col" class="px-6 py-3 text-center text-[16px] font-medium text-gray-900 uppercase dark:text-gray-400">ACTION</th>
                        </tr>
                    </thead>
                    <tbody class="divide-y divide-gray-200 bg-white dark:divide-gray-700">
                    {props?.data.map((data) => (
                        <tr key={data.CUSTOMER_ID}>
                            <td className="px-6 py-4 whitespace-nowrap text-[16px] font-medium text-gray-800 dark:text-gray-200">
                            {data.CUSTOMER_NAME}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-center text-[16px] text-gray-800 dark:text-gray-200">
                            {data.INVOICE_NO}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-center text-[16px] text-gray-800 dark:text-gray-200">
                            {data.INVOICE_CATEGORY}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-center text-[16px] text-gray-800 dark:text-gray-200">
                            {data.INVOICE_CURRENCY_CODE} {data.AMOUNT_DUE_REMAINING}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-center text-[16px] font-medium">
                            <button
                                type="button"
                                className="py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border-2
                                border-gray-900 font-semibold text-white focus:outline-none focus:ring-2
                                focus:ring-offset-2 transition-all text-sm bg-blue-950"
                                onClick={() =>
                                payNow(
                                    data.CUSTOMER_ID,
                                    data.CUSTOMER_NAME,
                                    data.CUSTOMER_NUMBER,
                                    data.CUSTOMER_TRX_ID,
                                    data.TRX_NUMBER,
                                    data.TRX_DATE,
                                    data.INVOICE_CURRENCY_CODE,
                                    data.INVOICE_NO,
                                    data.INVOICE_CATEGORY,
                                    data.AMOUNT_DUE_REMAINING,
                                    data.REMMITANCE_BANK_ACCOUNT_NUMBER,
                                    data.BU
                                )
                                }
                            >
                                PAY NOW
                            </button>
                            </td>
                        </tr>
                        ))}
                                   
                    </tbody>
                    </table>
                </div>
                </div>
            </div>
            </div>
    </div>
  )
}
