import React from 'react'
import 'react-toastify/dist/ReactToastify.css';

export default function NotFound() {
  return (
    <div className="flex items-center justify-center md:p-10 md:w-[80%] lg:w-[80%] w-[100%] p-0  h-[auto] rounded-[15px] bg-black/60">
    <div class="flex flex-col w-[100%] bg-white border shadow-sm rounded-xl dark:bg-gray-800 dark:border-gray-700 dark:shadow-slate-700/[.7]">
       
      <div class="p-4 md:p-5">              
              <h2 className='p-2 text-[60px]'>404 Page not found! <br/> <a type="button" class="py-3 mt-8 px-4 inline-flex justify-center items-center gap-2 rounded-md border-2
                          border-green-500 font-semibold text-white  
                      focus:outline-none focus:ring-2
                          focus:ring-offset-2 transition-all text-lg  
                              bg-green-500"
                              href={"/"}>
                      Go to Home Screen
                      </a></h2>
              </div>
      </div>
      </div>
      
  )
}
